/*
 * Here are the XMPP specifications for our custom read markers module.
 * Import this module and pass in a +stanza.io+ client instance. The code
 * below takes care to register the new stanzas.
 */
const unread = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'urn:xmpp:unread';

  const AckUnread = stanzas.define({
    name: 'ack',
    element: 'ack',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      jid: types.attribute('jid'),
    },
  });

  const QueryUnread = stanzas.define({
    name: 'query',
    element: 'query',
    namespace: NS,
    fields: {
      jid: types.attribute('jid'),
    },
  });

  const QueryResultOwn = stanzas.define({
    name: 'unreadMessages',
    element: 'unread-messages',
    namespace: NS,
    fields: {
      jid: types.attribute('jid'),
      amount: types.attribute('amount'),
    },
  });

  const QueryResultPeer = stanzas.define({
    name: 'firstUnread',
    element: 'unread-message',
    namespace: NS,
    fields: {
      jid: types.attribute('jid'),
      id: types.attribute('id'),
    },
  });

  const Unread = stanzas.define({
    name: 'unread',
    element: 'unread',
    namespace: 'urn:xmpp:unread',
    fields: {
      state: types.boolAttribute('state'),
    },
  });

  stanzas.extendMessage(Unread);

  // Add the unread custom stanza definitions
  stanzas.withIQ((Iq) => {
    stanzas.extend(Iq, AckUnread);
    stanzas.extend(Iq, QueryUnread);
    stanzas.add(Iq, 'unreadMessages', types.multiExtension(QueryResultOwn));
    stanzas.add(Iq, 'firstUnread', types.multiExtension(QueryResultPeer));
  });
};

export default unread;

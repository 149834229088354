import { Box, dataAttr, Flex, VStack } from '@hausgold/designsystem';
import {
  LiveHelp,
  LiveHelpFilled,
  Dashboard,
  DashboardOutline,
  AccountCircledFilled,
  AccountCircledOutline,
  HomeWorkFilled,
  HomeWorkOutline,
} from '@hausgold/designsystem/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useBottomHeight } from 'app-utils/contexts/bottomHeight';
import useResizeObserver from 'use-resize-observer';

/**
 * BottomNavigation
 *
 * Renders a bottom-sticky navigation bar, so the user can quickly switch
 * between the main pages of our application.
 */
const BottomNavigation = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const [, setBottomHeight] = useBottomHeight();

  const { ref } = useResizeObserver({
    onResize: ({ height }) => {
      setBottomHeight(`${height}px`);
    },
  });

  const hoverAndActiveStyles = {
    color: 'blue.400',
  };

  const iconStyles = {
    boxSize: 6,
  };

  const NAVIGATION_ITEMS = useMemo(
    () => [
      {
        title: t('sidebar.menu.homepage'),
        href: '/dashboard',
        activeOn: '/dashboard',
        iconActive: <Dashboard {...iconStyles} />,
        iconStandard: <DashboardOutline {...iconStyles} />,
      },
      {
        title: t('sidebar.menu.properties'),
        href: '/properties',
        activeOn: '/lead',
        iconActive: <HomeWorkFilled {...iconStyles} />,
        iconStandard: <HomeWorkOutline {...iconStyles} />,
      },
      {
        title: t('sidebar.menu.help'),
        href: '/help',
        activeOn: '/help',
        iconActive: <LiveHelpFilled {...iconStyles} />,
        iconStandard: <LiveHelp {...iconStyles} />,
      },
      {
        title: t('sidebar.menu.account'),
        href: '/settings/overview',
        activeOn: '/settings',
        iconActive: <AccountCircledFilled {...iconStyles} />,
        iconStandard: <AccountCircledOutline {...iconStyles} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box
      as="nav"
      // The ref's height ignores padding, so the Box includes sticky styles only and the Flex actual styles the content.
      ref={ref}
      position="sticky"
      zIndex="sticky"
      bottom={0}
      left={0}
    >
      <Flex
        justifyContent={{ base: 'space-around', sm: 'center' }}
        columnGap={{ base: 0, sm: 12 }}
        padding={2}
        paddingBottom={7}
        bgColor="white"
        // The shadow goes to the top instead of to the bottom like our predefined ones.
        boxShadow="0px -1px 5px 0px rgba(63, 62, 75, 0.10)"
      >
        {NAVIGATION_ITEMS.map(
          ({ title, href, activeOn, iconActive, iconStandard }) => {
            /*
             * Check if the navigation link is part of the current page and mark it as active.
             * It can either exact match (pathname === href) or partly match with activeOn where
             * activeOn starts with the value of pathname (e.g. /settings vs /settings/overview.
             * There are 2 different values for now (href + activeOn) because in case of /properties and /leads
             * there are different paths but "same" (by content) pages.
             */
            const isActive =
              router.pathname === href || router.pathname.startsWith(activeOn);

            return (
              <NextLink key={`bn_${title}`} href={href} passHref>
                <Box
                  as="a"
                  href={href}
                  _hover={hoverAndActiveStyles}
                  _active={hoverAndActiveStyles}
                  data-active={dataAttr(isActive)}
                  // The biggest translation is "properties" with 73px, so we ensure all have the same width.
                  width="74px"
                >
                  <VStack spacing={0}>
                    {isActive ? iconActive : iconStandard}
                    <Flex>{title}</Flex>
                  </VStack>
                </Box>
              </NextLink>
            );
          }
        )}
      </Flex>
    </Box>
  );
};

export default BottomNavigation;

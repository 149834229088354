import { Flex, useBreakpointValue } from '@hausgold/designsystem';
import SidebarHeader from 'app-components/page/Sidebar/SidebarHeader';
import { SIDEBAR_WIDTH } from 'app-utils/constants/ui';
import usePWADisplayMode from 'app-utils/hooks/usePWADisplayMode';
import UserAvatar from 'app-components/misc/UserAvatar';
import SidebarMenu from './SidebarMenu';

/**
 * Sidebar
 *
 * Displays the menu sidebar for the whole portal.
 *
 * @param {import('@hausgold/designsystem').FlexProps} props
 */
const Sidebar = (props) => {
  const { isWindowControlsOverlay } = usePWADisplayMode();
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const showSidebarHeader =
    (!isWindowControlsOverlay && !isMobile) ||
    (isWindowControlsOverlay && isMobile) ||
    (!isWindowControlsOverlay && isMobile);

  return (
    <Flex
      // Note: This is a default and is overwritten at the moment from props normally.
      position="sticky"
      alignSelf="flex-start"
      left={0}
      top={0}
      minH="100svh"
      bg="darkBlue.300"
      width={SIDEBAR_WIDTH}
      direction="column"
      flexShrink={0}
      {...props}
      data-testid="sidebar"
      color="white"
    >
      {showSidebarHeader && <SidebarHeader />}

      {/* Inner sidebar */}
      {/* CAUTION: See caution comment inside SidebarMenu before changing `px`. */}
      <Flex direction="column" py={6} px={4} flex={1}>
        <UserAvatar textProps={{ color: 'whiteAlpha.800' }} />
        <SidebarMenu flex={1} />
      </Flex>
    </Flex>
  );
};

export default Sidebar;

import { createContext, useContext } from 'react';

/**
 *
 * Bottom height context
 *
 * Responsible for storing the bottom's height.
 *
 * @type {React.Context<{headerHeight: null|number|string, setBottomHeight: (number) => void}>}
 */
export const BottomHeightContext = createContext({
  // Set the expected bottomHeight to prevent FOUCs in ~99% of cases.
  bottomHeight: '81px',
  setBottomHeight: () => {},
});

/**
 * UseBottomHeight
 *
 * Returns the value and the `bottomHeightContext`'s setter function
 * like `useState`.
 *
 * Example:
 *   const [bottomHeight, setBottomHeight] = useBottomHeight();
 *
 * @return {[null|number|string, function]}
 */
export const useBottomHeight = () => {
  const { bottomHeight, setBottomHeight } = useContext(BottomHeightContext);

  return [bottomHeight, setBottomHeight];
};

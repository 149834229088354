import { useBrokerRoom } from 'app-components/jabber/useRoom';
import { Menu, MenuButton, MenuList, Button } from '@hausgold/designsystem';
import { useUserId } from 'app-services/queries/bundleQueries';
import { Bell } from '@hausgold/designsystem/icons';
import NotificationList from 'app-components/notificationCenter/NotificationList';
import { useContext, useMemo } from 'react';
import { useLeadListNotificationsQuery } from 'app-graphql';
import useTracking from 'app-utils/hooks/useTracking';
import { eventBuilder, GROUP } from 'app-utils/constants/tracking';
import { MessageWithStanza } from 'app-components/jabber/messages';
import { useTranslation } from 'react-i18next';
import UnreadCount from 'app-components/jabber/UnreadCount';
import { JabberContext } from 'app-components/jabber/JabberProvider';

/**
 * The NotificationCenter handles the broker related chatroom which
 * shows updates of leads the broker own.
 */
const NotificationCenter = () => {
  const userId = useUserId();
  const { segmentTrack } = useTracking();
  const { t } = useTranslation();

  const {
    error,
    loading,
    messages,
    handleUnreadMessage,
    unreadMessages,
    hasUnreadMessages,
  } = useBrokerRoom({
    brokerId: userId,
    // We only show the last/newest 25 messages. This way we do not need some kind of pagination and the messages are probably too old anyway.
    amountHistoricMessages: 25,
  });

  const { unreadCount, brokersRoomJid } = useContext(JabberContext);

  // Get the brokers rooms (notification-center) unread message count from all the brokers unread conversations count.
  const unreadRoomCount = useMemo(
    () => (brokersRoomJid ? unreadCount[brokersRoomJid] : 0),
    [brokersRoomJid, unreadCount]
  );

  const leadIds = useMemo(
    () =>
      messages?.items
        ?.map(
          (item) =>
            // @ts-ignore the object of `lead_added` is not defined.
            item?.stanzas?.lead_added?.id ||
            // @ts-ignore the object of `lead_access_permitted? is not defined.
            item?.stanzas?.lead_access_permitted?.id
        )
        // Filter undefined
        .filter((id) => id !== undefined)
        // Filter unique (in theory only a canary/dev issue
        .filter((id, index, array) => array.indexOf(id) === index),
    [messages?.items]
  );

  // Use the lead ids from the stanzas to get the related lead identifiers (ZV) which are shown to the user.
  const [{ data: leadList }] = useLeadListNotificationsQuery({
    variables: {
      search: {
        ids: leadIds,
      },
    },
    pause: !leadIds?.length,
  });

  // Tracks when the notification center opens.
  const handleTrackOpen = () => {
    segmentTrack(eventBuilder('NotificationCenter', 'Opened'), {
      group: GROUP.notification,
    });
  };

  // Tracks when an interactive card was clicked and closes the center.
  const handleTrackCardClick = ({ onClose }: { onClose: () => void }) => {
    segmentTrack(eventBuilder('NotificationCenter', 'Interacted'), {
      group: GROUP.notification,
    });
    onClose();
  };

  const handleMarkAsRead = ({
    messageId,
    unread,
  }: {
    messageId: MessageWithStanza['id'];
    unread: boolean;
  }) => {
    if (unread) {
      handleUnreadMessage(messageId);
    }
  };

  const handleMarkAllAsRead = () => {
    handleUnreadMessage('all');
  };

  const hasMessages =
    messages && messages?.count > 0 && messages.items?.length > 0;

  return (
    <Menu
      isLazy
      autoSelect={false}
      closeOnSelect
      closeOnBlur
      onOpen={handleTrackOpen}
    >
      {({ onClose }) => (
        <>
          <MenuButton
            lineHeight={0}
            disabled={!!error || loading}
            sx={{ appRegion: 'no-drag' }}
          >
            <>
              <UnreadCount count={unreadRoomCount} />
              <Bell color="white" boxSize={5} />
            </>
          </MenuButton>
          <MenuList
            zIndex="dropdown"
            overflowY="auto"
            maxHeight="90svh"
            maxWidth="90svw"
            textColor="gray.500"
          >
            {hasMessages && (
              <Button
                variant="ghost"
                size="sm"
                mb={1}
                onClick={handleMarkAllAsRead}
                isDisabled={!hasUnreadMessages}
              >
                {t('notificationCenter.markAllAsRead')}
              </Button>
            )}

            <NotificationList
              messages={messages?.items || []}
              unreadMessages={unreadMessages}
              onClick={({ messageId, unread }) => {
                handleTrackCardClick({ onClose });

                if (unread) {
                  handleMarkAsRead({ messageId, unread });
                }
              }}
              // @ts-ignore This is fine!
              leadList={leadList?.leads?.nodes}
            />
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default NotificationCenter;

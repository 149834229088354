module.exports = {
  environment: 'development',
  /*
   * `.local` does not work as a cookie host; it will result in no cookies
   * being set.
   */
  cookieHost: undefined,
  trustedDomain: '.local',
  url: 'http://maklerportal-next.local',
  googleMaps: {
    apiKey: 'AIzaSyDyun7GaXe5sqqGqf9Afi2CsPmLXeh4_x0',
  },
  segment: {
    apiKey: null,
  },
  deployment: {
    commit: '',
    branch: '',
    deployedAt: '',
    s3Bucket: '',
  },
  appsignal: {
    appName: null,
    key: null,
    pushKey: null,
    revision: null,
    active: false,
  },
  firebase: {
    apiKey: 'AIzaSyD0OoaeUWfvC9Q0qicB2RQ0GQjEcaF03cs',
    authDomain: 'hausgold-canary.firebaseapp.com',
    databaseURL: 'https://hausgold-canary.firebaseio.com',
    projectId: 'hausgold-canary',
    storageBucket: 'hausgold-canary.appspot.com',
    messagingSenderId: '431836656218',
    appId: '1:431836656218:web:5a24722eb66ac804481e63',
    measurementId: 'G-6P6GZB8SHT',
  },
  features: {
    supportCenter: true,
  },
  zap: {
    support: 'https://hooks.zapier.com/hooks/catch/2548295/3qh50ga/',
    feedBackHighlightCard:
      'https://hooks.zapier.com/hooks/catch/2548295/2a1x5b2/',
  },
  contentful: {
    host: 'preview.contentful.com',
    space: '5qea3umoe8gl',
    accessToken: '6pcod9kgtIVrNuTeQkOnlQ8oWO0Mvvn9sykmVmL-hPU',
  },
};

/* eslint-disable react/no-unknown-property */
import { useMemo } from 'react';
import {
  Box,
  Flex,
  LogoMaklerportal,
  MinimalLayout as MinimalLayoutDs,
  useBreakpointValue,
  VStack,
  useTheme,
} from '@hausgold/designsystem';
import PropTypes from 'prop-types';
import { useHeaderHeight } from 'app-utils/contexts/headerHeight';
import Sidebar from 'app-components/page/Sidebar';
import Header, { WithWcoHeader } from 'app-components/page/Header';
import Main from 'app-components/page/Main';
import LegalLinks from 'app-components/misc/LegalLinks';
import { SIDEBAR_WIDTH } from 'app-utils/constants/ui';
import Head from 'next/head';
import usePWADisplayMode from 'app-utils/hooks/usePWADisplayMode';
import BottomNavigation from 'app-components/page/BottomNavigation';
import { useBottomHeight } from 'app-utils/contexts/bottomHeight';

/**
 * Minimal layout
 *
 * Layout to center the content for special "standalone" sites like all
 * authentication related pages
 *
 * @param {import('@hausgold/designsystem').BoxProps} props
 */
export const MinimalLayout = (props) => (
  <>
    <Head>
      <meta key="theme-color" name="theme-color" content="#f5f7fc" />
    </Head>

    <MinimalLayoutDs
      {...props}
      logo={<LogoMaklerportal />}
      footer={<LegalLinks spacing={10} />}
    />
  </>
);

MinimalLayout.propTypes = {
  showLogo: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
    PropTypes.array,
  ]),
};

MinimalLayout.defaultProps = {
  showLogo: true,
  children: null,
};

/**
 * Spacey layout
 *
 * Displays the page, just without a sidebar, allowing full sized content and
 * a lot of space.
 *
 * @param {Object & import('@hausgold/designsystem').BoxProps} props
 * @param {string} props.title
 * @param {React.ReactNode} props.children
 */
export const SpaceyLayout = ({
  title,
  children,
  showNotificationCenter,
  ...rest
}) => {
  const [headerHeight] = useHeaderHeight();

  return (
    <>
      <Head>
        <meta key="theme-color" name="theme-color" content="#f5f7fc" />
      </Head>

      <Header
        pageTitle={title}
        showNotificationCenter={showNotificationCenter}
      />
      <VStack
        alignItems="flex-start"
        justifyContent="space-between"
        w="100%"
        minH="100vh"
        style={{
          paddingTop: headerHeight,
        }}
      >
        <Main {...rest}>{children}</Main>
      </VStack>
    </>
  );
};

SpaceyLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
    PropTypes.array,
  ]),
  title: PropTypes.string,
  showNotificationCenter: PropTypes.bool,
};

SpaceyLayout.defaultProps = {
  children: null,
  title: null,
  showNotificationCenter: false,
};

/**
 * Sidebar layout
 *
 * Displays the page with a desktop and mobile sidebar.
 *
 * @param {Object & import('@hausgold/designsystem').BoxProps} props
 * @param {string} props.title
 * @param {React.ReactNode} props.children
 * @param {boolean} props.showNotificationCenter If true it shows the notification center button
 * @param {boolean} props.showBottomNavigation If true it shows the bottom navigation on mobile devices on SidebarLayout.
 */
export const SidebarLayout = ({
  title,
  children,
  showNotificationCenter,
  showBottomNavigation,
  ...rest
}) => {
  const [headerHeight] = useHeaderHeight();
  const [bottomHeight] = useBottomHeight();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  // Compute the final state if bottom navigation should be shown or not based on screen width and general page setting.
  const isBottomNavigationActive = isMobile && showBottomNavigation;

  const {
    colors: { darkBlue },
  } = useTheme();

  const { isWindowControlsOverlay } = usePWADisplayMode();

  // Changes the theme color according to different app display states.
  const content = useMemo(() => {
    let newContent = '#f5f7fc';
    if (isMobile || (!isMobile && isWindowControlsOverlay)) {
      newContent = darkBlue['200'];
    }

    return newContent;
  }, [isMobile, isWindowControlsOverlay, darkBlue]);

  return (
    <>
      <Head>
        <meta key="theme-color" name="theme-color" content={content} />
      </Head>

      <style jsx global>{`
        body {
          overflow-x: hidden;
        }
      `}</style>
      {isWindowControlsOverlay && (
        <WithWcoHeader
          headerProps={{
            pageTitle: title,
            showNotificationCenter,
          }}
        />
      )}

      <Flex>
        <Sidebar
          display={{
            base: 'none',
            md: 'flex',
          }}
        />
        <Box flexGrow={1} flexDirection="column" minH="100vh" w="full">
          {!isWindowControlsOverlay && (
            <Header
              pageTitle={title}
              showNotificationCenter={showNotificationCenter}
            />
          )}

          <Main
            minHeight={`calc(100vH - ${headerHeight} - ${
              isBottomNavigationActive ? bottomHeight : '0px'
            })`}
            /*
             * Fix px-value needs to be the same as in `<Sidebar/>`.
             * We need a fix overall-width px-value so Browsers can calculate relative width of child elements
             * like flex correctly instead of a "relative" percentage-value.
             */
            width={{
              base: '100vw',
              md: `calc(100vw - ${SIDEBAR_WIDTH})`,
            }}
            {...rest}
          >
            {children}
          </Main>

          {/* Mobile Bottom Navigation */}
          {isBottomNavigationActive && <BottomNavigation />}
        </Box>
      </Flex>
    </>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
    PropTypes.array,
  ]),
  title: PropTypes.string,
  showNotificationCenter: PropTypes.bool,
  showBottomNavigation: PropTypes.bool,
};

SidebarLayout.defaultProps = {
  children: null,
  title: null,
  showNotificationCenter: false,
  showBottomNavigation: false,
};

import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageWithStanza } from 'app-components/jabber/messages';
import { ID, Lead } from 'app-graphql';
import NotificationCard from 'app-components/notificationCenter/NotificationCard';
import { Box, Center, Divider } from '@hausgold/designsystem';
import { HouseCircled } from '@hausgold/designsystem/icons';
import { UnreadMessageState } from 'app-components/jabber/JabberProvider';

// Get the related lead identifier if known.
const getLeadIdentifier = ({
  leadList,
  stanzas,
}: {
  leadList:
    | ({ __typename?: 'Lead' | undefined } & Pick<Lead, 'id' | 'identifier'>)[]
    | undefined;
  stanzas: object | undefined;
}) => {
  // @ts-ignore the object of `lead_added` and `lead_access_permitted? are not as they are not defined.
  const leadId = (stanzas?.lead_added || stanzas?.lead_access_permitted)?.id;

  if (leadId && leadList) {
    return (
      leadList?.find((leadListItem) => leadListItem.id === leadId)
        ?.identifier || ''
    );
  }

  return '';
};

export interface NotificationListProps {
  /**
   * List of stanza messages.
   */
  messages: MessageWithStanza[];
  /**
   * Unread Messages
   * Unread state of the current messages the client received, archived and new ones.
   */
  unreadMessages: UnreadMessageState;
  /**
   * List of known leads with their id and identifier.
   */
  leadList?: {
    id: ID;
    identifier: Lead['identifier'];
  }[];
  /**
   * Function which is executed when the user clicks on a card.
   */
  onClick: (arg0: {
    messageId: MessageWithStanza['id'];
    unread: boolean;
  }) => void;
}

/**
 * Formats given notification data and pass those as list to the card render component.
 */
const NotificationList = ({
  messages,
  unreadMessages,
  leadList,
  onClick,
}: NotificationListProps) => {
  const { t } = useTranslation(['resource', 'common']);

  // Format the raw messages to ready-to-use format for our cards.
  const formattedMessages = useMemo(
    () =>
      messages.map((message) => {
        const {
          id,
          timestamp,
          stanzaNotificationHeader,
          stanzaNotificationBody,
          notificationLink,
          chatBody,
          stanzas,
          unread,
        } = message;

        const leadIdentifier = getLeadIdentifier({ stanzas, leadList });

        // If no header message exists it is either a chat message (from HG) or an error. In both cases we fall back to some generic.
        let stanzaNotificationHeaderFormatted = t(
          'notificationCenter.fallbackHeadline'
        );
        // If a header message exists we format it depending on if the ZV of the lead is present (more specific) or not (more generic).
        if (stanzaNotificationHeader) {
          if (leadIdentifier) {
            stanzaNotificationHeaderFormatted = `${stanzaNotificationHeader} ${leadIdentifier}`;
          } else {
            stanzaNotificationHeaderFormatted = `${t(
              'common:new'
            )}: ${stanzaNotificationHeader}`;
          }
        }

        // If there is no lead identifier or no link the related lead is not accessible (e.g. was canceled and is too old).
        const isLinkValid = !!notificationLink && !!leadIdentifier;

        return {
          id,
          headline: stanzaNotificationHeaderFormatted,
          body: (stanzaNotificationBody || chatBody)!,
          timestamp,
          link: (isLinkValid && notificationLink) || undefined,
          unread,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages, leadList]
  );

  return (
    <>
      {formattedMessages.map((message, index, array) => {
        const { id, headline, body, timestamp, link } = message;

        return (
          <Fragment key={id}>
            <NotificationCard
              headline={headline}
              body={body}
              Icon={HouseCircled}
              timestamp={timestamp}
              handleClick={() =>
                onClick({
                  messageId: id,
                  unread: unreadMessages && unreadMessages[id],
                })
              }
              link={link}
              unread={{ state: unreadMessages && unreadMessages[id] }}
            />
            {
              // Do not show the Divider beneath the last item.
              array.length !== index + 1 && <Divider />
            }
          </Fragment>
        );
      })}

      {!formattedMessages?.length && (
        <Box px={4} py={8} maxWidth="400px">
          <Center>{t('notificationCenter.empty')}</Center>
        </Box>
      )}
    </>
  );
};

export default NotificationList;

import { useTranslation } from 'react-i18next';
import { useUserQuery } from 'app-graphql';
import { formatFullName } from 'app-services/models/gql/preferencesSet';
import { Avatar, Flex, Text, TextProps } from '@hausgold/designsystem';

export type UserAvatarProps = {
  /**
   * Styleprops applied to the text part of the component.
   */
  textProps?: TextProps;
};

/**
 * UserAvatar
 *
 * Renders the users avatar together with its name and a welcome message.
 */
const UserAvatar = ({ textProps }: UserAvatarProps) => {
  const { t } = useTranslation();

  const [{ data }] = useUserQuery();

  const name = formatFullName(data?.broker?.preferences);
  const src = data?.broker?.avatar?.fileUrl;

  return (
    <Flex w="100%" alignItems="center" direction="column">
      {/* If a `name` is given, `Avatar` uses it in the first render despite a `src` being available, causing the flicker. */}
      <Avatar
        size="lg"
        boxSize="84px"
        name={!src ? name : undefined}
        src={src as string | undefined}
      />

      <Text size="sm" textAlign="center" mt={3} {...textProps}>
        {name
          ? `${t('sidebar.welcome')}, ${name}.`
          : `${t('sidebar.welcome')}!`}
      </Text>
    </Flex>
  );
};

export default UserAvatar;

import {
  Box,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
} from '@hausgold/designsystem';
import { useHeaderHeight } from 'app-utils/contexts/headerHeight';
import PropTypes from 'prop-types';
import useResizeObserver from 'use-resize-observer';
import usePWADisplayMode from 'app-utils/hooks/usePWADisplayMode';
import SidebarHeader from 'app-components/page/Sidebar/SidebarHeader';
import { SIDEBAR_WIDTH } from 'app-utils/constants/ui';
import NotificationCenter from 'app-components/notificationCenter/NotificationCenter';

const Header = ({ pageTitle, showNotificationCenter, ...rest }) => {
  const [, setHeaderHeight] = useHeaderHeight();

  const { ref } = useResizeObserver({
    onResize: ({ height }) => {
      setHeaderHeight(`${height}px`);
    },
  });

  const { isWindowControlsOverlay } = usePWADisplayMode();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Flex
      position="sticky"
      left={0}
      right={0}
      top={0}
      // This needs to be higher than the sticky from TabNavigations, so the NotificationCenter is displayed above the TabNavigation.
      zIndex="banner"
      height={12}
      color="white"
      alignItems="center"
      ref={ref}
      px={6}
      data-testid="header"
      sx={{
        '@media print': {
          position: 'static',
        },
      }}
      bgColor="darkBlue.200"
      {...rest}
    >
      <Text
        mb={0}
        color="inherit"
        position={{
          base: 'relative',
          md: 'static',
        }}
        pr={4}
        textTransform="uppercase"
        size="lg"
        noOfLines={1}
      >
        {pageTitle}
      </Text>

      <HStack
        alignItems="center"
        spacing={{
          base: 4,
          sm: 5,
          md: 10,
        }}
        mr={{
          base: 0,
          md: 5,
        }}
        ml={isWindowControlsOverlay && isMobile ? '0' : 'auto'}
        justifyContent="flex-end"
        sx={{
          '@media print': {
            visibility: 'hidden',
          },
        }}
      >
        {showNotificationCenter && <NotificationCenter />}
      </HStack>
    </Flex>
  );
};

Header.propTypes = {
  pageTitle: PropTypes.string,
  showNotificationCenter: PropTypes.bool,
};

Header.defaultProps = {
  pageTitle: null,
  showNotificationCenter: false,
};

export const WithWcoHeader = ({ headerProps }) => {
  const { isWindowControlsOverlay } = usePWADisplayMode();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Box
      bgColor="darkBlue.200"
      sx={{
        appRegion: 'drag',
        position: 'sticky',
        top: '0',
        left: '0',
        // This needs to be higher than the sticky from TabNavigations, so the NotificationCenter is displayed above the TabNavigation.
        zIndex: 'banner',
        height: 'env(titlebar-area-height, 48px)',
      }}
    >
      <Box
        sx={{
          '@media print': {
            position: 'static',
          },
          position: 'absolute',
          top: 'env(titlebar-area-y, 0)',
          left: 'env(titlebar-area-x, 0)',
          height: 'env(titlebar-area-height, 48px)',
          width:
            isWindowControlsOverlay && !isMobile
              ? `env(titlebar-area-width , 100%)`
              : '100%',
          transition: 'width .5s',
        }}
      >
        <HStack spacing={0}>
          {!isMobile && (
            <Box>
              <SidebarHeader
                width={SIDEBAR_WIDTH}
                height="env(titlebar-area-height, 48px)"
              />
            </Box>
          )}

          <Header
            {...headerProps}
            width="100%"
            height="env(titlebar-area-height, 48px)"
          />
        </HStack>
      </Box>
    </Box>
  );
};

export default Header;

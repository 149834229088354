import { Circle, CircleProps, Text } from '@hausgold/designsystem';
import { CONTENT_SWITCHER_SIZE } from 'app-utils/constants/ui';

interface UnreadCounterProps extends CircleProps {
  count: number | undefined;
}

const UnreadCount = ({ count, ...rest }: UnreadCounterProps) => {
  if (!count || count <= 0) {
    return null;
  }

  return (
    <Circle
      data-testid="unreadCounter"
      bg="blue.500"
      py={1}
      px={3}
      pr={8}
      mr={-7}
      position="relative"
      top="-5px"
      zIndex={-1}
      display="inline"
      {...rest}
    >
      <Text
        display="inline"
        color="white"
        m="0"
        fontSize={CONTENT_SWITCHER_SIZE}
        fontWeight={600}
      >
        {count > 9 ? '9+' : count}
      </Text>
    </Circle>
  );
};

export default UnreadCount;
